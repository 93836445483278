<template>
  <div fluid class="warehouse-detail" v-if="warehouse">
    <Breadcrumb :items="breadCrumbs" />
    <v-container>
      <h1>{{ warehouseName }}</h1>
      <v-row>
        <v-col cols="12" md="6">
          <img
            class="warehouse-map"
            :alt="$ebsn.meta(warehouse, 'warehouse_detail.IMAGE.alt')"
            :src="$ebsn.meta(warehouse, 'warehouse_detail.IMAGE.location')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="0" class="h-100" :key="warehouse.warehouseId">
            <v-card-text class="pt-0">
              <div class="mb-6">
                <div class="warehouse-info-title">
                  {{ $t("storeLocator.information") }}
                </div>
                <div>
                  {{ formattedAddress(warehouse) }}
                </div>
              </div>
              <div v-if="email" class="mb-4">
                <div class="font-weight-bold">Email:</div>

                <a
                  :aria-label="`Scrivi una mail a ${email}`"
                  :title="`Scrivi una mail a ${email}`"
                  class="text-caption font-weight-bold primary--text text--lighten-1 text-decoration-none"
                  :href="'mailto:' + email"
                  >{{ email }}</a
                >
              </div>
              <v-spacer />
              <!-- <div class="my-3">
                <div class="font-weight-bold">
                  {{ $t("storeLocator.divisions") }}
                </div>
                <img
                  class="ml-1 align-center"
                  v-for="(repartoIcon, idx) in filteredAddressClasses"
                  :key="idx"
                  :src="repartoIcon.iconSource"
                  :alt="repartoIcon.name"
                  height="20"
                  width="20"
                />
              </div> -->

              <span class="warehouse-info-title mt-5 mb-4">
                {{ $t("storeLocator.openingTimes") }}
              </span>
              <WarehouseHours
                v-if="warehouse"
                :serviceHours="warehouse.serviceHours"
                class="mb-4"
              />

              <span class="warehouse-info-title mt-5 mb-4">Servizi</span>
              <div
                v-if="warehouse.warehouseClass"
                class="warehouse-classes d-flex align-center mb-4"
              >
                <template v-for="(wClass, index) in warehouse.warehouseClass">
                  <img
                    v-if="wClass.iconSource"
                    :key="index"
                    :src="wClass.iconSource"
                    :alt="wClass.name"
                    class="warehouse-class-img mr-4"
                  />
                </template>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="warehouse.address"
                title="Indicazioni stradali"
                class="text-caption font-weight-bold primary--text text-decoration-none"
                link
                text
                :href="
                  `https://www.google.com/maps/dir/?dir_action=navigate&api=1&destination=${warehouse.address.latitude},${warehouse.address.longitude}`
                "
                target="_blank"
              >
                <!-- :to="{
                  path: `https://www.google.com/maps/dir/?dir_action=navigate&api=1&destination=${warehouse.address.latitude},${warehouse.address.longitude}`
                }" -->
                {{ $t("storeLocator.googleAddress") }}
                <v-icon x-small color="primary">$chevronRight</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <ebsn-meta
        class="description"
        :target="warehouse"
        path="warehouse_detail.DESCRIPTION"
        tag="div"
        @click="clicked"
      ></ebsn-meta>
    </v-container>
    <v-container class="dpoejwofirehiu" :key="warehouse.warehouseId">
      <!-- <product-list-slider
        :layout="22"
        :warehouseId="warehouse.warehouseId"
        :promo="true"
        :shuffle="true"
        :limit="12"
        :title="$t('warehouseDetail.productsPromoTitle')"
        :description="$t('warehouseDetail.productsPromoDescr')"
      /> -->
      <!-- <ProductSliderWrapper
        :id="index"
        :counter="sliderCounter"
        :layout="22"
        :warehouseId="warehouse.warehouseId"
        :title="subCategory.name"
        :category="category"
        :subCategory="subCategory"
        :showArrows="true"
        :showBullets="true"
        @updateSliderWrapperViewable="updateSliderWrapperViewable"
      /> -->
      <ProductSliderWrapper
        :layout="22"
        :warehouseId="0"
        :promo="true"
        :shuffle="true"
        :limit="12"
        :title="$t('warehouseDetail.productsPromoTitle')"
        :description="$t('warehouseDetail.productsPromoDescr')"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.warehouse-detail {
  .warehouse-map {
    width: 100%;
  }
  .warehouse-info-title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 8px;
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import WarehouseHours from "@/components/store/WarehouseHours.vue";
// import ProductListSlider from "@/components/product/ProductListSlider";
import ProductSliderWrapper from "@/components/product/ProductSliderWrapper.vue";
import { calculateBreadcrumbs } from "@/components/store/warehouseHelper";

import AddressService from "~/service/addressService";

import get from "lodash/get";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseDetail",
  components: {
    Breadcrumb,
    WarehouseHours,
    ProductSliderWrapper
    // ProductListSlider
  },
  data() {
    return {
      warehouseSlug: null,
      warehouse: {}
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "351px";
        case "sm":
          return "573px";
        case "md":
          return "563px";
        case "lg":
          return "754px";
        case "xl":
          return "1061px";
      }
      return "850px";
    },
    mapHeight() {
      return "320px";
    },
    breadCrumbs() {
      return calculateBreadcrumbs(this.$route.fullPath.substring(1));
    },
    email() {
      return this.$ebsn.meta(this.warehouse, "warehouse_info.EMAIL", null);
    },
    warehouseName() {
      return get(
        this.warehouse,
        "metaData.warehouse_detail.NAME",
        this.warehouse.name
      );
    }
    // filteredAddressClasses() {
    //   if (this.warehouse.address && this.warehouse.address.addressClass) {
    //     return this.warehouse.address.addressClass.filter(item => {
    //       return !(
    //         item.iconSource === null || item.iconSource.trim().length === 0
    //       );
    //     });
    //   } else {
    //     return [];
    //   }
    // }
  },
  methods: {
    formattedAddress(warehouse) {
      if (warehouse && warehouse.address) {
        return `${warehouse.address.address1}, ${warehouse.address.city} - (${warehouse.address.province}) ${warehouse.address.postalcode}`;
      }
    },
    async reload() {
      let data = await AddressService.findWarehouse({
        parent_warehouse_slug: this.warehouseSlug
      });
      if (data && data.warehouses && data.warehouses.length > 0) {
        this.warehouse = data.warehouses[0];
      }
    }
  },
  mounted() {
    this.warehouseSlug = this.$route.params.warehouseSlug;
    this.reload();
  },
  watch: {
    "$route.params.warehouseSlug"(value) {
      this.warehouseSlug = value;
      this.reload();
    }
  }
};
</script>
