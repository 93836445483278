<template>
  <v-list-group class="warehouse-hours" value="true">
    <div>
      <v-list-item>
        <v-list-item-title
          class="d-flex mr-16 opening-day-title"
          v-if="todayHours"
        >
          <span class="week-day">{{ todayHours.weekDay }}</span>
          <v-spacer />
          <span
            class="hours"
            v-if="todayHours.hours"
            v-html="todayHours.hours"
          ></span>
        </v-list-item-title>
      </v-list-item>
    </div>
    <div v-if="otherDayHours && otherDayHours.length > 0">
      <v-list-item
        v-for="day in otherDayHours"
        :key="day.weekDay"
        class="opening-day-item"
      >
        <v-list-item-title class="d-flex mr-16 opening-day-title">
          <span class="week-day">{{ day.weekDay }}</span>
          <v-spacer />
          <span class="hours" v-if="day.hours" v-html="day.hours"></span>
        </v-list-item-title>
      </v-list-item>
    </div>
  </v-list-group>
</template>
<style lang="scss">
.warehouse-hours {
  .v-icon.v-icon {
    font-size: 14px;
  }
  .v-list-group__header {
    padding: 0;
    display: none;
  }
  .hours {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      .partial-hours:not(:last-child) {
        margin-right: 4px;
        &::after {
          content: ",";
        }
      }
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
</style>
<script>
import { calculateHourRow } from "@/components/store/warehouseHelper";

export default {
  name: "WarehouseHours",
  props: {
    serviceHours: { type: Object, required: true }
  },
  data() {
    return {
      todayHours: null,
      otherDayHours: [],
      offsetDay: 1
    };
  },
  methods: {},
  mounted() {
    // day of week from server: 1 sunday, 7 saturday
    // dayJs: 0 sunday, 6 saturday
    let today = this.$dayjs();
    let todayHours = calculateHourRow(this.serviceHours, today, this.offsetDay);

    this.todayHours = {
      weekDay: today.format("dddd"),
      hours: todayHours ? todayHours : "Chiuso"
    };

    for (let i = 0; i < 6; i++) {
      let dayToCalc = this.$dayjs().add(i + 1, "day");
      let otherDay = calculateHourRow(
        this.serviceHours,
        dayToCalc,
        this.offsetDay
      );
      this.otherDayHours.push({
        weekDay: dayToCalc.format("dddd"),
        hours: otherDay ? otherDay : this.$t("warehouses.closed")
      });
    }
  }
};
</script>
